import React, { useState } from "react"
import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import { navigate } from "gatsby"
import * as styles from "../assets/css/indexNavigation.module.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Card3 = ({ data }) => {
  const { image, back } = data
  const [show1, setShow1] = useState(true)
  const [show2, setShow2] = useState(true)
  const [show3, setShow3] = useState(true)
  const [show4, setShow4] = useState(true)
  const [show5, setShow5] = useState(true)
  const [show6, setShow6] = useState(true)
  const [show7, setShow7] = useState(true)
  const [show8, setShow8] = useState(true)
  const [show9, setShow9] = useState(true)
  const [show10, setShow10] = useState(true)
  const [show11, setShow11] = useState(true)
  const [show12, setShow12] = useState(true)

  return (
    <Layout>
      <div className={styles.backResponsive}>
        <div
          className={styles.back}
          onClick={() => navigate(-1)}
          onKeyDown={() => navigate(-1)}
          role="button"
          tabIndex="0"
        >
          <GatsbyImage image={getImage(back)} title={back.title} alt="Zurück" />
        </div>
      </div>

      <div className={styles.neurologeHeader}>Schwerpunkte der Praxis</div>
      <div className={styles.neurologeContentContainer}>
        <div className={styles.neurologeText1}>
          <p>
            Als Facharzt für Neurologie fühle ich mich prinzipiell für alle
            neurologischen Erkrankungen zuständig, wie bei jeder Praxis besteht
            jedoch für einige Themen eine Schwerpunkt-Setzung, was Diagnostik
            und Therapie betrifft.
          </p>
        </div>
      </div>
      <div className={styles.neurologeContentImageContainer}>
        <div className={styles.neurologeContentImageSchwerpunkte}>
          <GatsbyImage
            image={getImage(image)}
            title={image.title}
            alt="Computertomographie"
          />
        </div>
      </div>

      <div className={styles.neurologeContentContainer2Schwerpunkte}>
        <div className={styles.neurologeText2Schwerpunkte}>
          <p>
            Hier finden Sie eine Auflistung von Erkrankungen, die in meiner
            Praxis diagnostiziert und behandelt werden.
          </p>
          <div className={styles.neurologeHeader2}>Schwerpunkte</div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow1(s => !s)}
            onKeyDown={() => setShow1(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Alzheimer/Demenz</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show1 ? "block" : "none" }}>↓</b>
              <b style={{ display: show1 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show1 ? "none" : "block" }}
          >
            Abklärung von Demenzerkrankungen, Beratung einschließlich
            Angehörigenberatung, Behandlung und Therapiekontrolle
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow2(s => !s)}
            onKeyDown={() => setShow2(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Merkstörungen</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show2 ? "block" : "none" }}>↓</b>
              <b style={{ display: show2 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show2 ? "none" : "block" }}
          >
            Abklärungen von Merkstörungen, bei Bedarf mit neuropsychologischer
            Testung, Beratung, bei Bedarf Therapieeinleitung
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow3(s => !s)}
            onKeyDown={() => setShow3(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Epilepsie</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show3 ? "block" : "none" }}>↓</b>
              <b style={{ display: show3 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show3 ? "none" : "block" }}
          >
            Erstdiagnostik mit Bildgebung des Gehirns (CT/MR), EEG, Beratung,
            medikamentöse Therapieeinleitung und Therapiekontrolle bei Epilepsie
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow4(s => !s)}
            onKeyDown={() => setShow4(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Ischias- und Rückenschmerz</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show4 ? "block" : "none" }}>↓</b>
              <b style={{ display: show4 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show4 ? "none" : "block" }}
          >
            Abklärung mittels klinischer Untersuchung, ev. ENG/EMG, Zuweisung
            zur Bildgebung (MRT), konservative Schmerztherapie, Zuweisung zur
            Physiotherapie, Zusammenarbeit mit anderen Fachrichtungen
            (Orthopädie, Schmerztherapie- zB Schmerzambulanz MKH Vorau)
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow5(s => !s)}
            onKeyDown={() => setShow5(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Schlaganfall Vor- und Nachsorge</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show5 ? "block" : "none" }}>↓</b>
              <b style={{ display: show5 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show5 ? "none" : "block" }}
          >
            Vorsorge (Rsikiofaktoren-Analyse, Ultraschall der hirnversorgenden
            Gefäße zur Beurteilung einer eventuellen Arteriosklerose, Zuweisung
            zur Bildgebung (CT/MR), Nachsorge (medikamentös, Kontrolle der
            Risikofaktoren, Ultraschallkontrolle bei Gefäßveränderungen,
            Zuweisung zu Physio-, Logo- und Ergotherapie)
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow6(s => !s)}
            onKeyDown={() => setShow6(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Karpaltunnelsyndrom</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show6 ? "block" : "none" }}>↓</b>
              <b style={{ display: show6 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show6 ? "none" : "block" }}
          >
            Erstdiagnostik mittels klinischer Untersuchung und
            Eletroneurographie, Therapie- Beratung, Nachkontrollen falls
            erforderlich, Zusammenarbeit mit anderen Fachrichtungen (Orthopädie,
            Chirurgie)
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow7(s => !s)}
            onKeyDown={() => setShow7(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Kopfschmerz/Migräne</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show7 ? "block" : "none" }}>↓</b>
              <b style={{ display: show7 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show7 ? "none" : "block" }}
          >
            Erstdiagnostik (incl. CT/MRT, ev. EEG), Kopfschmerz-Beratung,
            Therapieeinleitung (medikamentös, komplementär-nichtmedikamentös),
            Therapiekontrolle
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow8(s => !s)}
            onKeyDown={() => setShow8(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Morbus Parkinson</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show8 ? "block" : "none" }}>↓</b>
              <b style={{ display: show8 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show8 ? "none" : "block" }}
          >
            Erstdiagnose, Abklärung mittels CT/MRT, ev. Nuklearmedizinische
            Darstellung des Gehirn-Dopaminstoffwechsels (über
            Radiol.Univ.-Klinik LKH Graz), Therapieeinleitung,
            Therapiekontrolle, Veranlassung zusätzlicher Therapieformen
            (Physio-, Logo-, Ergotherapie), Reha-Ansuchen
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow9(s => !s)}
            onKeyDown={() => setShow9(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Polyneuropathie</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show9 ? "block" : "none" }}>↓</b>
              <b style={{ display: show9 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show9 ? "none" : "block" }}
          >
            Erstdiagnose mittels ENG/EMG, Ursachen-Abklärung,
            Therapieeinleitung, Therapiekontrolle
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow10(s => !s)}
            onKeyDown={() => setShow10(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Restless-Legs-Syndrom</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show10 ? "block" : "none" }}>↓</b>
              <b style={{ display: show10 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show10 ? "none" : "block" }}
          >
            Erstdiagnose, Therapieberatung, Therapieeinleitung,
            Therapiekontrolle
          </div>

          <div
            className={styles.schwerpunkteContentHeader}
            onClick={() => setShow11(s => !s)}
            onKeyDown={() => setShow11(s => !s)}
            role="button"
            tabIndex="0"
          >
            <div>Schwindel</div>
            <div className={styles.schwerpunkteContentHeaderArrow}>
              <b style={{ display: show11 ? "block" : "none" }}>↓</b>
              <b style={{ display: show11 ? "none" : "block" }}>↑</b>
            </div>
          </div>
          <div
            className={styles.schwerpunkteContent}
            style={{ display: show11 ? "none" : "block" }}
          >
            Abklärung mittels klinischer Untersuchung, ev. Lagerungstests, ev.
            Gefäß-Ultraschall, Zuweisung zur Gehirn-Bildgebung (CT/MRT),
            Zusammenarbeit mit anderen Fachrichtungen (HNO, Interne)
          </div>

      
        </div>
      </div>

      <Navigation></Navigation>
    </Layout>
  )
}

export default Card3

export const pageQuery = graphql`
  query SchwerpunktQuery {
    image: contentfulAsset(contentful_id: { eq: "1gU92uBGcvZkva6V5Nr3yp" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    back: contentfulAsset(contentful_id: { eq: "6fycJDkI3crd9QifanGGwI" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`

// extracted by mini-css-extract-plugin
export var back = "indexNavigation-module--back--3H6Oy";
export var neurologeHeader = "indexNavigation-module--neurologeHeader--lXiUm";
export var neurologeContentContainer = "indexNavigation-module--neurologeContentContainer--1rq9Q";
export var neurologeText1 = "indexNavigation-module--neurologeText1--3Dsfg";
export var neurologeContentImage = "indexNavigation-module--neurologeContentImage--2ZM9N";
export var neurologeContentContainer2 = "indexNavigation-module--neurologeContentContainer2--3Z8nu";
export var neurologeText2 = "indexNavigation-module--neurologeText2--3bsC8";
export var neurologeContentImageSchwerpunkte = "indexNavigation-module--neurologeContentImageSchwerpunkte--3PSS6";
export var neurologeContentContainer2Schwerpunkte = "indexNavigation-module--neurologeContentContainer2Schwerpunkte--5oEm4";
export var neurologeContentContainer2Schwerpunkte2 = "indexNavigation-module--neurologeContentContainer2Schwerpunkte2--3zQMV";
export var neurologeText2Schwerpunkte = "indexNavigation-module--neurologeText2Schwerpunkte--CoJEF";
export var neurologeHeader2 = "indexNavigation-module--neurologeHeader2--2JxZt";
export var neurologeHeader3 = "indexNavigation-module--neurologeHeader3--2iZdL";
export var neurologeText2Schwerpunkte2 = "indexNavigation-module--neurologeText2Schwerpunkte2--1Gwd4";
export var schwerpunkteContentHeader = "indexNavigation-module--schwerpunkteContentHeader--3w6lv";
export var schwerpunkteContentHeaderArrow = "indexNavigation-module--schwerpunkteContentHeaderArrow--2BAW4";
export var schwerpunkteContent = "indexNavigation-module--schwerpunkteContent--3Siwe";
export var zitatText = "indexNavigation-module--zitatText--2ieKp";
export var neurologeContentImageContainerGanzheitlich = "indexNavigation-module--neurologeContentImageContainerGanzheitlich--3COrN";
export var neurologeContentImageGanzheitlich = "indexNavigation-module--neurologeContentImageGanzheitlich--59U5g";
export var neurologeContentContainer2Ganzheitlich = "indexNavigation-module--neurologeContentContainer2Ganzheitlich--ezC3Y";
export var neurologeText2Ganzheitlich = "indexNavigation-module--neurologeText2Ganzheitlich--2YifZ";
export var backResponsive = "indexNavigation-module--backResponsive--2xiXh";
export var neurologeContentImageContainer = "indexNavigation-module--neurologeContentImageContainer--22agJ";